import React, {Component} from 'react';
import PesquisaSatisfacaoService from '../../service/PesquisaSatisfacaoService'
import { Redirect } from 'react-router';
import { createBrowserHistory } from 'history';

export class PesquisaSatisfacao extends Component {
    constructor(props) {				
        super(props);			
        this.state = {
            txtConfirmar: "Confirmar",
            idAssistencia: null,
            idPesquisaSatisfacao: null,
            idAssistServico :null, 
            idEnvioSms: null,
            pergunta1:"",
            pergunta2:"",
            notaPergunta1:null,
            notaPergunta2:null,
            descricao:"",
            nomeMontador:"",
            nomeTitular:"",            
            redirectSuccess: null,            
            t1n0_selected : false,
            t1n1_selected : false,
            t1n2_selected : false,
            t1n3_selected : false,
            t1n4_selected : false,
            t1n5_selected : false,
            t1n6_selected : false,
            t1n7_selected : false,
            t1n8_selected : false,
            t1n9_selected : false,
            t1n10_selected : false,
            t2n0_selected : false,
            t2n1_selected : false,
            t2n2_selected : false,
            t2n3_selected : false,
            t2n4_selected : false,
            t2n5_selected : false,
            t2n6_selected : false,
            t2n7_selected : false,
            t2n8_selected : false,
            t2n9_selected : false,
            t2n10_selected : false,
            showSaveButton: false,
			id: this.props.id
        };        
		this.pesquisaSatisfacaoService = new PesquisaSatisfacaoService();        
        this.onSalvar = this.onSalvar.bind(this);
        this.validate = this.validate.bind(this);
    }   
   
	componentWillMount(){
		
	}

    componentDidMount() {
		this.onPesquisaSatisfacao(this.state.id);  
    }

    capitalizeFirstLetter(str) {
        let name = str;
        const arrNome = name.split(" ");
        let primeiroNome = arrNome[0];

        // converting first letter to uppercase
        const capitalized = primeiroNome.charAt(0).toUpperCase() + primeiroNome.slice(1).toLowerCase();
    
        return capitalized;
    }

    onPesquisaSatisfacao(id) {	
        this.pesquisaSatisfacaoService.findPesquisa(id)
            .then(res => {				                
				var body = res.data;					
				if(body.length) {

					this.setState({						
                        idAssistencia: body[0].idAssistencia,                        
                        idPesquisaSatisfacao: body[0].idPesquisaSatisfacao,
                        nomeMontador: this.capitalizeFirstLetter(body[0].nomeMontador)+ " ?",
                        nomeTitular: this.capitalizeFirstLetter(body[0].nomeTitular) ,
                        idAssistServico: body[0].idAssistServico,
                        idEnvioSms: body[0].idEnvioSms						
					});	
					
					this.onBuscarPerguntas(id);
					
				} else {
					alert("Pesquisa já respondida ou não encontrada");					
                    this.setState({redirectSuccess: false});
				}                								
            })
            .catch((err) => {
				alert(err.response.data);
                this.setState({redirectSuccess: false})
            })
					
    }

    onBuscarPerguntas(id) {
        this.pesquisaSatisfacaoService.findPerguntas(id)
            .then(res => {				           
				var body = res.data;					  
                this.setState({
                    pergunta2: body[1].descricao,
                    pergunta1: body[0].descricao,
                    idPerguntaPesquisa1: body[0].idPerguntaPesquisa,
                    idPerguntaPesquisa2: body[1].idPerguntaPesquisa												
                });
			
				this.setState({showSaveButton: true});								
				
            })
            .catch((err) => {	
				alert(err.response.data);
                this.setState({redirectSuccess: false})
            })	
    }

    validate(nota1, nota2) {

        if (nota1=== null){
            alert("Favor responder a pergunta 1 antes de enviar");
            this.setState({showSaveButton: true});
            return false;
        }

        if (nota2===null){
            alert("Favor responder a pergunta 2 antes de enviar");
            this.setState({showSaveButton: true});
            return false;
        }

        return true;
    }

    disableSaveButton(callback) {
        this.refs.btn.style.display = 'none';
    }

    onSalvar() {

        this.refs.btnConfirmar.setAttribute("disabled", "disabled");

        const dados = {
            idPesquisaSatisfacao    : this.state.idPesquisaSatisfacao,
            idPerguntaPesquisa1     : this.state.idPerguntaPesquisa1,
            notaPergunta1           : this.state.notaPergunta1 ,
            idPerguntaPesquisa2     : this.state.idPerguntaPesquisa2,
            notaPergunta2           : this.state.notaPergunta2,
            descricao               : this.state.descricao,
            idAssistencia           : this.state.idAssistencia,
            idAssistServico         : this.state.idAssistServico            
          };

          
        if (!this.state.showSaveButton) {
            return;
        }
        
        this.setState({showSaveButton: false})

        if (this.validate(dados.notaPergunta1,dados.notaPergunta2)){
            this.pesquisaSatisfacaoService.salvar(dados, this.state.id).then(res =>	{				
				this.setState({redirectSuccess: true})									
			})
            .catch((err) => {
				alert(err.response.data);
                this.setState({redirectSuccess: false})
            })
        }
    }

    setPadroesTd1 = () => {
        this.setState({ t1n0_selected: false });
        this.setState({ t1n1_selected: false });
        this.setState({ t1n2_selected: false });
        this.setState({ t1n3_selected: false });
        this.setState({ t1n4_selected: false });
        this.setState({ t1n5_selected: false });
        this.setState({ t1n6_selected: false });
        this.setState({ t1n7_selected: false });
        this.setState({ t1n8_selected: false });
        this.setState({ t1n9_selected: false });
        this.setState({ t1n10_selected: false });
    }

    setPadroesTd2 = () => {
        this.setState({ t2n0_selected: false });
        this.setState({ t2n1_selected: false });
        this.setState({ t2n2_selected: false });
        this.setState({ t2n3_selected: false });
        this.setState({ t2n4_selected: false });
        this.setState({ t2n5_selected: false });
        this.setState({ t2n6_selected: false });
        this.setState({ t2n7_selected: false });
        this.setState({ t2n8_selected: false });
        this.setState({ t2n9_selected: false });
        this.setState({ t2n10_selected: false });
    }

    setNotaPergunta1 = (event) => {
        let escolha = Number(event.target.innerText);
        this.setState({ notaPergunta1: Number(event.target.innerText) });
        this.setPadroesTd1();

        if (escolha===0){
            this.setState({ t1n0_selected: true });
        }
        if (escolha===1){
            this.setState({ t1n1_selected: true });
        }
        if (escolha===2){
            this.setState({ t1n2_selected: true });
        }
        if (escolha===3){
            this.setState({ t1n3_selected: true });
        }
        if (escolha===4){
            this.setState({ t1n4_selected: true });
        }
        if (escolha===5){
            this.setState({ t1n5_selected: true });
        }
        if (escolha===6){
            this.setState({ t1n6_selected: true });
        }
        if (escolha===7){
            this.setState({ t1n7_selected: true });
        }
        if (escolha===8){
            this.setState({ t1n8_selected: true });
        }
        if (escolha===9){
            this.setState({ t1n9_selected: true });
        }
        if (escolha===10){
            this.setState({ t1n10_selected: true });
        }
    }
    
    setNotaPergunta2 = (event) => {
        let escolha = Number(event.target.innerText);
        this.setState({ notaPergunta2: Number(event.target.innerText) });
        this.setPadroesTd2();
        
        if (escolha===0){
            this.setState({ t2n0_selected: true });
        }
        if (escolha===1){
            this.setState({ t2n1_selected: true });
        }
        if (escolha===2){
            this.setState({ t2n2_selected: true });
        }
        if (escolha===3){
            this.setState({ t2n3_selected: true });
        }
        if (escolha===4){
            this.setState({ t2n4_selected: true });
        }
        if (escolha===5){
            this.setState({ t2n5_selected: true });
        }
        if (escolha===6){
            this.setState({ t2n6_selected: true });
        }
        if (escolha===7){
            this.setState({ t2n7_selected: true });
        }
        if (escolha===8){
            this.setState({ t2n8_selected: true });
        }
        if (escolha===9){
            this.setState({ t2n9_selected: true });
        }
        if (escolha===10){
            this.setState({ t2n10_selected: true });
        }
    }
    

    render() {		
				
		var styles = {
			btnConfirmar: {'background-color': '#f0983e', 'padding': '10px 20px', 'font-weight': 'bold', 'color': '#060853',  'border': '2px solid #060853'},
			cardbody: {'border': 'none'},			
			naoRecomendaria: {'font-size': '12px', 'color': 'gray', 'margin-left': '6px'},			
			tituloPage: {'width': '100%', 'text-align': 'center', 'font-weight': 'bold', 'font-size': '16px', 'margin-bottom': '20px', 'color': '#f0983e'},
            numeroSelected: {'width': '9.1%', 'color': 'black', 'padding': '5px 0px', 'border': '2px solid  black', 'text-align': 'center', 'font-weight': 'bold'},
            numero: {'width': '9.1%','padding': '5px 0px','text-align': 'center','font-weight': 'bold'},
            certeza: {'float': 'right', 'width': '45%', 'font-size': '12px', 'color': 'gray', 'text-align': 'right',  'margin-right': '6px'},
			table: {'width': '100%'}
		}
		
		if(this.state.redirectSuccess != null) {
			if(this.state.redirectSuccess) {
				return <Redirect to={'/pesquisa-respondida'}/>
			} else {
				return <Redirect to={'/pesquisa-erro'}/>
		    }   
		}                        

        return (
            <div className="p-grid">
    			<nav className="navbar" >
					<img className="logoMMS" alt="MMS" src="/assets/layout/images/logoMMS.png"/>
				</nav>
                <div className="p-col-12">
                   <div className="textoTop">
                        <div style={styles.cardbody}>
                            <div className='bemvindo'>Ol&aacute;,<strong> {this.state.nomeTitular} !</strong></div>
                            <div className='subTituloTop' >Queremos ouvir voc&ecirc; :) </div>
                            <div style={styles.subTituloPage}>{this.state.pergunta1}  </div>
                            
                            <table id="table" style={styles.table}><tbody>
                                <td  id="t1n0" style={(this.state.t1n0_selected === true ? styles.numeroSelected : styles.numero)}   onClick={(e) => this.setNotaPergunta1(e)}  >0</td>
                                <td  id="t1n1" style={(this.state.t1n1_selected === true ? styles.numeroSelected : styles.numero)}   onClick={(e) => this.setNotaPergunta1(e)}  >1</td>
                                <td  id="t1n2" style={(this.state.t1n2_selected === true ? styles.numeroSelected : styles.numero)}   onClick={(e) => this.setNotaPergunta1(e)}  >2</td>
                                <td  id="t1n3" style={(this.state.t1n3_selected === true ? styles.numeroSelected : styles.numero)}   onClick={(e) => this.setNotaPergunta1(e)}  >3</td>
                                <td  id="t1n4" style={(this.state.t1n4_selected === true ? styles.numeroSelected : styles.numero)}   onClick={(e) => this.setNotaPergunta1(e)}  >4</td>
                                <td  id="t1n5" style={(this.state.t1n5_selected === true ? styles.numeroSelected : styles.numero)}   onClick={(e) => this.setNotaPergunta1(e)}  >5</td>
                                <td  id="t1n6" style={(this.state.t1n6_selected === true ? styles.numeroSelected : styles.numero)}   onClick={(e) => this.setNotaPergunta1(e)}  >6</td>
                                <td  id="t1n7" style={(this.state.t1n7_selected === true ? styles.numeroSelected : styles.numero)}   onClick={(e) => this.setNotaPergunta1(e)}  >7</td>
                                <td  id="t1n8" style={(this.state.t1n8_selected === true ? styles.numeroSelected : styles.numero)}   onClick={(e) => this.setNotaPergunta1(e)}  >8</td>
                                <td  id="t1n9" style={(this.state.t1n9_selected === true ? styles.numeroSelected : styles.numero)}   onClick={(e) => this.setNotaPergunta1(e)}  >9</td>
                                <td  id="t1n10" style={(this.state.t1n10_selected === true ? styles.numeroSelected : styles.numero)}   onClick={(e) => this.setNotaPergunta1(e)}  >10</td>
                                </tbody>
                            </table>
                            <div className="rec">
                                <span style={styles.naoRecomendaria}>N&atilde;o indicaria</span>
                                <span style={styles.certeza} >Indicaria</span>
                            </div>
                        </div>

                        <div style={styles.cardbody}>
                            <div style={styles.subTituloPage}>{this.state.pergunta2}  {this.state.nomeMontador} </div>

                            <table  id="table2" style={styles.table}>
								<tbody>
									<td id="t2n0" style={(this.state.t2n0_selected === true ? styles.numeroSelected : styles.numero)}   onClick={(e) => this.setNotaPergunta2(e)}  >0</td>
									<td id="t2n1" style={(this.state.t2n1_selected === true ? styles.numeroSelected : styles.numero)}   onClick={(e) => this.setNotaPergunta2(e)}  >1</td>
									<td id="t2n2" style={(this.state.t2n2_selected === true ? styles.numeroSelected : styles.numero)}   onClick={(e) => this.setNotaPergunta2(e)}  >2</td>
									<td id="t2n3" style={(this.state.t2n3_selected === true ? styles.numeroSelected : styles.numero)}   onClick={(e) => this.setNotaPergunta2(e)}  >3</td>
									<td id="t2n4" style={(this.state.t2n4_selected === true ? styles.numeroSelected : styles.numero)}   onClick={(e) => this.setNotaPergunta2(e)}  >4</td>
									<td id="t2n5" style={(this.state.t2n5_selected === true ? styles.numeroSelected : styles.numero)}   onClick={(e) => this.setNotaPergunta2(e)}  >5</td>
									<td id="t2n6" style={(this.state.t2n6_selected === true ? styles.numeroSelected : styles.numero)}   onClick={(e) => this.setNotaPergunta2(e)}  >6</td>
									<td id="t2n7" style={(this.state.t2n7_selected === true ? styles.numeroSelected : styles.numero)}   onClick={(e) => this.setNotaPergunta2(e)}  >7</td>
									<td id="t2n8" style={(this.state.t2n8_selected === true ? styles.numeroSelected : styles.numero)}   onClick={(e) => this.setNotaPergunta2(e)}  >8</td>
									<td id="t2n9" style={(this.state.t2n9_selected === true ? styles.numeroSelected : styles.numero)}   onClick={(e) => this.setNotaPergunta2(e)}  >9</td>
									<td id="t2n10" style={(this.state.t2n10_selected === true ? styles.numeroSelected : styles.numero)}   onClick={(e) => this.setNotaPergunta2(e)}>10</td>
								</tbody>
                            </table>
                            <div className="rec">
                                <span style={styles.naoRecomendaria}>P&eacute;ssimo</span>
                                <span style={styles.certeza} >Excelente</span>
                            </div>
                        </div>
		
                        <div className='divComentario' style={styles.cardbody}>
                            <div className='labelComentario'  >Deixe aqui seu coment&aacute;rio (Opcional)</div>
                            <textarea className='inputComentario'  id="descricaoPesquisa" maxLength="250"
                             value={this.state.descricao} 
                             onChange={(e) => this.setState({ descricao: e.target.value })} rows="5"></textarea>
                        </div>

                         <br/><br/>

                        <div className="p-grid" >
                            <div className="p-col-12" style={{"textAlign": "center"}}>
                            { (this.state.notaPergunta1 &&  this.state.notaPergunta2) ? 
                                <button label={this.state.txtConfirmar} className="btnConfirmar" ref="btnConfirmar" onClick={this.onSalvar} >ENVIAR</button>                                                         
                                :
                                <button label={this.state.txtConfirmar} className="btnConfirmar" ref="btnConfirmar" disabled >ENVIAR</button>       
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}